<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="670px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="programa.nombre"
                            label="Programa"
                            :error-messages="programaErrors"
                            @input="$v.programa.nombre.$touch()"
                            @blur="$v.programa.nombre.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            label="Descripción"
                            v-model="programa.descripcion"
                            outlined
                            counter="500"
                            :error-messages="descripcionErrors"             
                            @change="$v.programa.descripcion.$touch()"
                            @blur="$v.programa.descripcion.$touch()"
                            required>
                        </v-textarea>  
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de Ejecución"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fechaEjecucion" 
                                locale="es-CR" 
                                @input="menu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input label="Adjuntar Evidencias"
                            accept=".png, .jpeg, .jpg"
                            v-model="evidencias"
                            multiple
                            show-size                                
                            clearable
                            :error-messages="evidenciasErrors"             
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarEstrella">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from "vuelidate/lib/validators";

const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        fechaMoment () {
            return this.fechaEjecucion ? moment(this.fechaEjecucion).format('DD/MM/YYYY') : '';
        },
        programaErrors() {
            const errors = []
            if (!this.$v.programa.nombre.$dirty) return errors            
            !this.$v.programa.nombre.required && errors.push('El nombre del programa es requerido.')
            !this.$v.programa.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descripcionErrors() {
            const errors = []
            if (!this.$v.programa.descripcion.$dirty) return errors            
            !this.$v.programa.descripcion.required && errors.push('La descripción es requerida.')
            !this.$v.programa.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        evidenciasErrors () {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('La evidencia es requerida.')
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        }, 
    },
    props: [
        'tipo',
        'titulo',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        programa: {
            nombre: '',
            descripcion: '',
        },
        fechaEjecucion: new Date().toISOString().substr(0, 10),
        evidencias: [],
    }),
    validations: {
        programa: {
            nombre: { required, maxLength: maxLength(100) },
            descripcion: { required, maxLength: maxLength(500) },
        },
        evidencias: { required, maxFileSize },
    },
    methods: {
        cancelar() {
            this.programa = {};
            this.fechaEjecucion = new Date().toISOString().substr(0, 10);
            this.evidencias = [];
            this.dialog = false;
            this.loading = false;
            this.$v.programa.$reset();
            this.$v.evidencias.$reset();
        },
        agregarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.evidencias.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append("fecha", this.fechaEjecucion);
                formData.append("idInstalacion", this.idInstalacion);
                formData.append("idUsuario", this.idUsuario);
                formData.append("programaS", JSON.stringify(this.programa));
                axios.post("/estrellas/agregaProgramaGestion", formData)
                .then(response => {
                    this.$store.commit('historialEstrella', response.data)
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Programa agregado", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch("noti", { 
                          mensaje: "Ocurrió un error no se agregó el programa",
                          color: "error"
                      });
                      this.cancelar();
                    }
                  }
                });
            }
        }
    }
};
</script>
